export function sleep(ms) {
    let timeoutId = undefined;
    const promise = new Promise((resolve) => {
        timeoutId = setTimeout(resolve, ms);
    });
    promise.dispose = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    };
    return promise;
}
