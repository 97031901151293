import { join as joinPaths, basename, dirname } from "path";
import { ApiResponseError } from "@codesandbox/api";
import { Disposable, newId, SerialQueue, Emitter, bedrockFS, Barrier, } from "@codesandbox/pitcher-common";
import JSZip from "jszip";
import { minimatch } from "minimatch";
import { MemoryFS } from "../../common/MemoryFS";
import { isText } from "../is-binary";
import { moduleToFile } from "./FilesState";
const NodeType = bedrockFS.NodeType;
const ROOT_ID = bedrockFS.ROOT_ID;
const WORKSPACE_PATH = "/nodebox";
function removeWorkspacePath(path) {
    if (path.startsWith(WORKSPACE_PATH)) {
        return path.substring(WORKSPACE_PATH.length);
    }
    return path;
}
// These folders are irrelevant for browser sandboxes, but it will be when converted to a devbox
const IGNORED_ROOT_DIRS = [".codesandbox", ".devcontainer"];
const IGNORED_ROOT_FILES = [".eslintrc.cjs", ".eslintrc.json"];
export class BrowserFSClient extends Disposable {
    constructor(filesState, initialSandboxFs, apiUtils, seamlessFork, getLangClient) {
        super();
        this.filesState = filesState;
        this.apiUtils = apiUtils;
        this.seamlessFork = seamlessFork;
        this.getLangClient = getLangClient;
        this.memoryFS = new MemoryFS();
        this.operationQueue = new SerialQueue("fs-client");
        this.workspacePath = WORKSPACE_PATH;
        this.onFSErrorEmitter = new Emitter();
        this.onFSError = this.onFSErrorEmitter.event;
        this.onFSSyncEmitter = new Emitter();
        this.onFSSync = this.onFSSyncEmitter.event;
        this.onPendingOperationsChangeEmitter = new Emitter();
        this.onPendingOperationsChange = this.onPendingOperationsChangeEmitter.event;
        this.readyPromise = Promise.resolve();
        this.directoryIdToShortId = new Map();
        this.clock = 0;
        // When ability to seamless fork we queue and FS requests until the seamless fork is done. Pitcher Client takes care of opening
        // this barrier when ready
        this.seamlessForkBarrier = new Barrier();
        this.activeWatchers = new Map();
        // Expose FS methods in MemoryFS
        this.getPathFromId = this.memoryFS.getPathFromId.bind(this.memoryFS);
        this.getIdFromPath = this.memoryFS.getIdFromPath.bind(this.memoryFS);
        this.watchQueue = new Map();
        // We want to identify folders we do not care about
        const ignoredDirectoryShortIds = initialSandboxFs.directories
            .filter((directory) => !directory.directoryShortid &&
            IGNORED_ROOT_DIRS.includes(directory.title))
            .map((directory) => directory.shortid);
        const treeNodes = [
            ...initialSandboxFs.directories
                .filter(
            // We filter out the folders we do not care about
            (directory) => !ignoredDirectoryShortIds.includes(directory.shortid))
                .map((directory) => ({
                id: directory.shortid,
                type: NodeType.Directory,
                name: directory.title,
                parentId: (directory.directoryShortid || ROOT_ID),
            })),
            ...initialSandboxFs.modules
                // We filter out files in the folders we do not care about. This is just a flat check,
                // but that is fine for the two folders in question, they'll never have sub directories
                .filter((module) => 
            // Allow files in root that or not ignored
            (!module.directoryShortid &&
                !IGNORED_ROOT_FILES.includes(module.title)) ||
                // And files not in root that is not in an ignored folder
                (module.directoryShortid &&
                    !ignoredDirectoryShortIds.includes(module.directoryShortid)))
                .map((module) => ({
                id: module.shortid,
                type: NodeType.File,
                name: module.title,
                parentId: (module.directoryShortid || ROOT_ID),
            })),
        ];
        this.memoryFS.populateTreeFromJSON({
            clock: this.clock,
            treeNodes,
        });
        this.clock += 1;
        this.onFSSyncEmitter.fire({
            opCount: 1,
        });
        // Emit watch events in a throttled way, every 100ms
        setInterval(() => {
            this.emitWatchEvents();
        }, 100);
    }
    getPendingOperations() {
        return Array.from(this.memoryFS["pendingOperations"].values());
    }
    getDirShortId(dirId) {
        if (dirId === ROOT_ID) {
            return null;
        }
        return this.directoryIdToShortId.get(dirId) ?? dirId;
    }
    async createModule(opts) {
        const { internalId, name, directoryNodeId, code, isBinary } = opts;
        const mod = await this.apiUtils.createModule({
            title: name,
            directoryShortId: this.getDirShortId(directoryNodeId),
            code,
            isBinary,
        });
        this.filesState.addFile(internalId, moduleToFile(mod));
    }
    applyInternalOperation(operation, shouldSync = true) {
        const nextClock = this.clock;
        this.clock += 1;
        this.memoryFS.receiveNotification({
            clock: nextClock,
            operation,
        });
        if (shouldSync) {
            this.memoryFS.syncFSTree([]);
        }
    }
    mapOperationToPitcherRequestMethod(operation) {
        if (operation.type === "create") {
            return "fs/writeFile";
        }
        if (operation.type === "delete") {
            return "fs/remove";
        }
        return "fs/rename";
    }
    async applyFSOperation(operation) {
        const operationId = this.memoryFS.applyPendingOperation(operation);
        if (this.seamlessFork(this.mapOperationToPitcherRequestMethod(operation))) {
            await this.seamlessForkBarrier.wait();
        }
        if (operationId === false) {
            return Promise.resolve();
        }
        this.onPendingOperationsChangeEmitter.fire(this.getPendingOperations());
        // operations should be applied one by one to ensure we don't get issues like `parent dir does not exist`
        // if an operation depends on the previous one
        return this.operationQueue.add(() => {
            const applyOperation = async () => {
                switch (operation.type) {
                    case "create": {
                        if (operation.newEntry.type === NodeType.File) {
                            await this.createModule({
                                internalId: operation.newEntry.id,
                                name: operation.newEntry.name,
                                directoryNodeId: operation.parentId,
                                code: "",
                                isBinary: false,
                            });
                        }
                        else {
                            const createdDir = await this.apiUtils.createDirectory(operation.newEntry.name, this.getDirShortId(operation.parentId));
                            this.directoryIdToShortId.set(operation.newEntry.id, createdDir.shortid);
                        }
                        break;
                    }
                    case "delete": {
                        const entryId = operation.id;
                        const file = this.filesState.get(entryId);
                        if (file) {
                            await this.apiUtils.deleteModule(file.shortid);
                            this.filesState.deleteFile(entryId);
                        }
                        else {
                            const shortid = this.getDirShortId(entryId);
                            if (!shortid) {
                                throw new Error("Cannot delete root directory");
                            }
                            await this.apiUtils.deleteDirectory(shortid);
                        }
                        break;
                    }
                    case "move": {
                        const file = this.filesState.get(operation.id);
                        if (operation.parentId) {
                            if (file) {
                                await this.apiUtils.changeModuleDirectory(file.shortid, this.getDirShortId(operation.parentId));
                            }
                            else {
                                const dirId = this.getDirShortId(operation.id);
                                if (!dirId) {
                                    throw new Error("Root directory cannot be moved");
                                }
                                const parentId = this.getDirShortId(operation.parentId);
                                await this.apiUtils.changeDirectoryParentDirectory(dirId, parentId);
                            }
                        }
                        if (operation.name) {
                            if (file) {
                                await this.apiUtils.saveModuleTitle(file.shortid, operation.name);
                            }
                            else {
                                const dirId = this.getDirShortId(operation.id);
                                if (!dirId) {
                                    throw new Error("Root directory cannot be renamed");
                                }
                                await this.apiUtils.saveDirectoryTitle(dirId, operation.name);
                            }
                        }
                        break;
                    }
                }
            };
            let filepath = null;
            if (operation.type === "delete") {
                filepath = this.memoryFS.getPathFromId(operation.id);
            }
            if (operation.type === "move") {
                filepath = this.memoryFS.getPathFromId(operation.id);
            }
            return applyOperation()
                .then(() => {
                // Sync will happen in the .finally(() => ...)
                this.applyInternalOperation(operation, false);
            })
                .catch((err) => {
                this.onFSErrorEmitter.fire({
                    message: err.message,
                });
            })
                .finally(() => {
                const opCount = this.memoryFS.syncFSTree([operationId]);
                // Not sure if this should fire an event?
                this.onFSSyncEmitter.fire({
                    opCount,
                });
                this.onPendingOperationsChangeEmitter.fire(this.getPendingOperations());
                if (operation.type === "create") {
                    const newNodePath = this.memoryFS.getPathFromId(operation.newEntry.id);
                    if (newNodePath) {
                        this.queueWatchEvent(newNodePath, "add");
                    }
                }
                else if (operation.type === "delete") {
                    if (filepath) {
                        this.queueWatchEvent(filepath, "remove");
                    }
                }
                else if (operation.type === "move") {
                    const newNodePath = this.memoryFS.getPathFromId(operation.id);
                    if (newNodePath && filepath !== newNodePath) {
                        if (filepath) {
                            this.queueWatchEvent(filepath, "remove");
                        }
                        this.queueWatchEvent(newNodePath, "add");
                    }
                    else if (filepath) {
                        this.queueWatchEvent(filepath, "change");
                    }
                }
            });
        });
    }
    createNewDirectory(name, parentId) {
        const id = newId();
        this.applyFSOperation({
            type: "create",
            parentId,
            newEntry: {
                id,
                type: bedrockFS.NodeType.Directory,
                name,
            },
            // eslint-disable-next-line
        }).catch(console.error);
        return id;
    }
    createParentDirs(parts, parentId) {
        let previousNode = this.memoryFS.tree.getNodeById(parentId);
        if (!previousNode || !previousNode.isDirNode()) {
            throw new Error(previousNode
                ? `${previousNode.path} is not a directory`
                : "Parent directory not found");
        }
        for (const part of parts) {
            const foundDirectory = previousNode.children.find((c) => c.name === part);
            if (!foundDirectory) {
                const dirId = this.createNewDirectory(part, previousNode.id);
                previousNode = this.memoryFS.tree.getNodeById(dirId);
            }
            else {
                if (!foundDirectory.isDirNode()) {
                    throw new Error(`${foundDirectory.path} already exists but is not a directory`);
                }
                previousNode = foundDirectory;
            }
        }
        return previousNode;
    }
    /**
     * When you have a path you know is relative, but you want to to ensure the correct format with
     * prefixed "/" and type safety
     */
    asRelativeWorkspacePath(path) {
        return joinPaths("/", path);
    }
    /**
     * When you know you have an absolute path, but want to ensure it to be this users workspace and have type safety
     */
    asAbsoluteWorkspacePath(path) {
        return joinPaths(this.workspacePath, removeWorkspacePath(path));
    }
    /**
     * When you have a type safe absolute path and want to make it relative
     */
    absoluteToRelativeWorkspacePath(path) {
        return joinPaths("/", removeWorkspacePath(path));
    }
    /**
     * When you have a tpe safe relative path and want to make it absolute to this users workspace path
     */
    relativeToAbsoluteWorkspacePath(path) {
        return joinPaths(this.workspacePath, path);
    }
    resolveRelativeWorkspacePath(path) {
        return joinPaths("/", removeWorkspacePath(path));
    }
    resolveAbsoluteWorkspacePath(path) {
        return this.asAbsoluteWorkspacePath(path);
    }
    resync() {
        return Promise.resolve();
    }
    async createFile(name, parentId) {
        const parts = name.split("/").filter(Boolean);
        const filename = parts.pop();
        if (!filename) {
            throw new Error("File name is undefined");
        }
        const parentDirNode = this.createParentDirs(parts, parentId);
        const id = newId();
        await this.applyFSOperation({
            type: "create",
            parentId: parentDirNode.id,
            newEntry: {
                id,
                type: bedrockFS.NodeType.File,
                name: filename,
            },
            // eslint-disable-next-line
        }).catch(console.error);
        return {
            id,
            filepath: this.getPathFromId(id),
        };
    }
    isFile(id) {
        const node = this.memoryFS.getNodeById(id);
        return node?.type === NodeType.File;
    }
    /**
     * Create a directory with the provided name within the directory of the
     * given parent id.
     * */
    createDirectory(name, parentId) {
        const parts = name.split("/").filter(Boolean);
        if (!parts.length) {
            throw new Error("Directory name is undefined");
        }
        const dirNode = this.createParentDirs(parts, parentId);
        return dirNode.id;
    }
    /**
     * delete a node from the FS
     * */
    deleteNode(id) {
        this.applyFSOperation({
            type: "delete",
            id,
            // eslint-disable-next-line
        }).catch(console.error);
    }
    /**
     * rename a node from the FS
     * */
    move(id, opts) {
        const { newName, newParentId } = opts;
        this.applyFSOperation({
            type: "move",
            id,
            parentId: newParentId,
            name: newName,
            // eslint-disable-next-line
        }).catch(console.error);
    }
    async search({ text: query, glob, caseSensitivity, }) {
        const result = [];
        for (const [fileId, node] of this.memoryFS.tree.nodes.entries()) {
            const matchPath = glob
                ? glob.split("\n").every((g) => minimatch(node.path, g))
                : true;
            if (node.isFile() && matchPath) {
                const file = this.filesState.get(node.id);
                if (file) {
                    let lineNumber = 1;
                    for (const line of file.content.split("\n")) {
                        const regex = new RegExp(query, caseSensitivity === "enabled" ? "g" : "gi");
                        if (regex.exec(line)?.[0]) {
                            result.push({
                                fileId,
                                lineNumber,
                                absoluteOffset: NaN,
                                lines: { text: line },
                                submatches: findQueryPositions(line, query, caseSensitivity === "enabled"),
                            });
                        }
                        lineNumber++;
                    }
                }
            }
        }
        return Promise.resolve(result);
    }
    streamingSearch(_params, _onMatches, _abort) {
        return Promise.resolve({ hitLimit: false });
    }
    pathSearch(_params) {
        return Promise.resolve({
            matches: [],
        });
    }
    async uploadFile(name, content, parentId) {
        const parentDir = this.getPathFromId(parentId);
        if (!parentDir) {
            throw new Error("parent dir not found");
        }
        const filepath = joinPaths(parentDir, name);
        await this.writeFile(filepath, content, true, true);
        const fileId = this.getIdFromPath(filepath);
        return {
            id: fileId,
            filepath: filepath,
        };
    }
    async download(path) {
        if (path) {
            const nodeId = this.getIdFromPath(path);
            if (nodeId) {
                const node = this.memoryFS.getNodeById(nodeId);
                if (node?.isFile()) {
                    const content = this.readFileSync(path);
                    return {
                        // TODO: Add content type?
                        downloadUrl: URL.createObjectURL(new Blob([content])),
                    };
                }
            }
        }
        const zip = new JSZip();
        for (const [Id, entry] of this.memoryFS.tree.nodes) {
            const path = this.memoryFS.getPathFromId(Id);
            if (path && entry.isFile()) {
                zip.file(path, this.readFileSync(path));
            }
        }
        return zip.generateAsync({ type: "blob" }).then(function (content) {
            return {
                downloadUrl: URL.createObjectURL(content),
            };
        });
    }
    readFileSync(path) {
        path = this.absoluteToRelativeWorkspacePath(path);
        const fileId = this.getIdFromPath(path);
        const fileContent = fileId ? this.filesState.getContent(fileId) : null;
        if (fileContent == null) {
            throw new Error("File not found");
        }
        if (typeof fileContent === "string") {
            const enc = new TextEncoder();
            return enc.encode(fileContent);
        }
        else {
            return fileContent;
        }
    }
    async readFile(path) {
        try {
            path = this.absoluteToRelativeWorkspacePath(path);
            const content = this.readFileSync(path);
            return {
                type: "ok",
                result: {
                    content,
                },
            };
        }
        catch (err) {
            try {
                const langClient = this.getLangClient();
                if (langClient) {
                    const content = await langClient.browserLSP.readFile(path);
                    return {
                        type: "ok",
                        result: {
                            content,
                        },
                    };
                }
            }
            catch (err) {
                // do nothing...
            }
            return {
                type: "error",
                error: "File not found",
                errno: 2,
            };
        }
    }
    async readdir(path) {
        path = this.absoluteToRelativeWorkspacePath(path);
        const fileId = this.getIdFromPath(path);
        const node = fileId ? this.memoryFS.getNodeById(fileId) : null;
        if (!node) {
            return {
                type: "error",
                error: "Directory not found",
                errno: 2,
            };
        }
        else if (!node.isDirNode()) {
            return {
                type: "error",
                error: "Is not a directory",
                errno: 20,
            };
        }
        else {
            return {
                type: "ok",
                result: {
                    entries: node.children.map((child) => {
                        return {
                            name: child.name,
                            type: child.type,
                            // Symlinks don't exist in bedrockFS just yet
                            isSymlink: false,
                        };
                    }),
                },
            };
        }
    }
    async writeFile(path, content, create, 
    // Isn't this always true? When does a write not overwrite the content?
    _overwrite) {
        path = this.absoluteToRelativeWorkspacePath(path);
        if (this.seamlessFork("fs/writeFile")) {
            await this.seamlessForkBarrier.wait();
        }
        const id = this.getIdFromPath(path);
        const getTextContent = async () => {
            const filename = basename(path);
            const isBinary = !isText(filename, content);
            if (!isBinary) {
                const decoder = new TextDecoder();
                const decoded = decoder.decode(content);
                return {
                    value: decoded,
                    isBinary: false,
                };
            }
            else {
                const blob = new Blob([content]);
                const dataUri = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = function () {
                        const dataUrl = reader.result;
                        resolve(dataUrl);
                    };
                    reader.readAsDataURL(blob);
                });
                const { data: result } = await this.apiUtils.createUpload(filename, dataUri);
                return {
                    value: result.url,
                    isBinary: true,
                };
            }
        };
        const { value: textValue, isBinary } = await getTextContent();
        if (!id) {
            if (create) {
                const parts = path.split("/").filter(Boolean);
                const name = parts.pop();
                const parentPath = parts.join("/") || "/";
                if (!name || !parentPath) {
                    throw new Error("Invalid path");
                }
                const parentId = this.getIdFromPath(parentPath);
                if (!parentId) {
                    return {
                        type: "error",
                        error: "Parent directory not found",
                        errno: 2,
                    };
                }
                const node = this.memoryFS.getNodeById(parentId);
                if (!node || !node.isDirNode()) {
                    return {
                        type: "error",
                        error: "Parent directory not found",
                        errno: 2,
                    };
                }
                const fileNodeId = newId();
                try {
                    await this.createModule({
                        internalId: fileNodeId,
                        name,
                        directoryNodeId: parentId,
                        code: textValue,
                        isBinary,
                    });
                    if (isBinary) {
                        this.filesState.setBinaryContent(fileNodeId, content);
                    }
                    this.applyInternalOperation({
                        type: "create",
                        parentId,
                        newEntry: {
                            id: fileNodeId,
                            type: NodeType.File,
                            name,
                        },
                    });
                    this.queueWatchEvent(path, "add");
                    return {
                        type: "ok",
                        result: {},
                    };
                }
                catch (err) {
                    if (err instanceof ApiResponseError) {
                        return {
                            type: "error",
                            error: err.parsedErrorMessage || err.message,
                            errno: -1,
                        };
                    }
                    return {
                        type: "error",
                        error: "Something went wrong when creating a new file",
                        errno: -1,
                    };
                }
            }
            else {
                return {
                    type: "error",
                    error: "File not found",
                    errno: 2,
                };
            }
        }
        const file = this.filesState.get(id);
        if (!file) {
            return {
                type: "error",
                error: "File not found",
                errno: 2,
            };
        }
        if (isBinary) {
            this.filesState.setBinaryContent(id, content);
        }
        await this.apiUtils.saveModuleCode(file.shortid, textValue);
        file.content = textValue;
        file.savedContent = textValue;
        this.queueWatchEvent(path, "change");
        return {
            type: "ok",
            result: {},
        };
    }
    async stat(path) {
        path = this.absoluteToRelativeWorkspacePath(path);
        const fileId = this.getIdFromPath(path);
        const file = fileId ? this.filesState.get(fileId) : null;
        if (fileId) {
            if (file) {
                return {
                    type: "ok",
                    result: {
                        type: bedrockFS.NodeType.File,
                        isSymlink: false,
                        size: file.content.length,
                        // Does pitcher use seconds (default nodejs) or mtimeMs?
                        mtime: file.updatedAt / 1000,
                        ctime: file.createdAt / 1000,
                        atime: file.updatedAt / 1000,
                    },
                };
            }
            const node = this.memoryFS.getNodeById(fileId);
            if (node?.isDirNode()) {
                let lowestCreatedAt = Number.MAX_SAFE_INTEGER;
                let lastUpdatedAt = 0;
                for (const child of node.children) {
                    const foundFile = this.filesState.get(child.id);
                    if (foundFile) {
                        lowestCreatedAt = Math.min(foundFile.createdAt, lowestCreatedAt);
                        lastUpdatedAt = Math.max(foundFile.updatedAt, lastUpdatedAt);
                    }
                }
                return {
                    type: "ok",
                    result: {
                        type: node.type,
                        isSymlink: false,
                        size: 8,
                        // Does pitcher use seconds (default nodejs) or mtimeMs?
                        mtime: lastUpdatedAt / 1000,
                        ctime: lowestCreatedAt / 1000,
                        atime: lastUpdatedAt / 1000,
                    },
                };
            }
        }
        try {
            const langClient = this.getLangClient();
            if (langClient) {
                const stats = await langClient.browserLSP.statFile(path);
                return {
                    type: "ok",
                    result: {
                        type: stats.type === "dir" ? NodeType.Directory : NodeType.File,
                        isSymlink: false,
                        size: stats.size,
                        mtime: stats.mtimeMs / 1000,
                        ctime: stats.ctimeMs / 1000,
                        atime: stats.atimeMs / 1000,
                    },
                };
            }
        }
        catch (err) {
            // do nothing...
        }
        return {
            type: "error",
            error: "File not found",
            errno: 2,
        };
    }
    async copy(from, to, recursive, 
    // dunno what the use of this is exactly
    overwrite) {
        from = this.absoluteToRelativeWorkspacePath(from);
        to = this.absoluteToRelativeWorkspacePath(to);
        if (recursive) {
            const nodeId = this.getIdFromPath(from);
            if (!nodeId) {
                return {
                    type: "error",
                    error: "File not found",
                    errno: 2,
                };
            }
            const node = this.memoryFS.getNodeById(nodeId);
            if (!node) {
                return {
                    type: "error",
                    error: "File not found",
                    errno: 2,
                };
            }
            if (node.isDirNode()) {
                // Ensure target directory
                const mkdirResult = await this.mkdir(to, true);
                if (mkdirResult.type !== "ok") {
                    return mkdirResult;
                }
                // Loop over the child nodes and run copy for those as well
                // As long as there are no symlinks this shouldn't create recursion
                // (and currently we don't support symlinks in the mem-fs)
                for (const child of node.children) {
                    const copyResult = await this.copy(child.path, `${to}/${child.name}`, recursive, overwrite);
                    if (copyResult.type !== "ok") {
                        return copyResult;
                    }
                }
            }
        }
        const source = await this.readFile(from);
        if (source.type === "ok") {
            await this.writeFile(to, source.result.content);
        }
        else {
            return {
                type: "error",
                error: source.error,
                errno: source.errno,
            };
        }
        return {
            type: "ok",
            result: {},
        };
    }
    async rename(from, to, overwrite) {
        from = this.absoluteToRelativeWorkspacePath(from);
        to = this.absoluteToRelativeWorkspacePath(to);
        const sourceId = this.getIdFromPath(from);
        if (!sourceId) {
            return {
                type: "error",
                error: "File not found",
                errno: 2,
            };
        }
        const targetId = this.getIdFromPath(to);
        if (targetId) {
            if (overwrite) {
                await this.remove(to);
            }
            else {
                return {
                    type: "error",
                    error: "File already exists",
                    errno: 17,
                };
            }
        }
        const toParts = dirname(to).split("/").filter(Boolean);
        const toName = basename(to);
        if (!toName) {
            throw new Error("Invalid to path");
        }
        const parentNode = this.createParentDirs(toParts, ROOT_ID);
        await this.applyFSOperation({
            type: "move",
            id: sourceId,
            parentId: parentNode.id,
            name: toName,
        });
        return {
            type: "ok",
            result: {},
        };
    }
    async remove(path, recursive) {
        path = this.absoluteToRelativeWorkspacePath(path);
        const id = this.getIdFromPath(path);
        if (!id) {
            // file does not exist, so removing it is a no-op
            return {
                type: "ok",
                result: {},
            };
        }
        if (!recursive) {
            const node = this.memoryFS.getNodeById(id);
            if (node) {
                if (node.isDirNode()) {
                    if (node.children.length) {
                        return {
                            type: "error",
                            error: "Directory not empty",
                            errno: 17,
                        };
                    }
                }
            }
        }
        await this.applyFSOperation({
            type: "delete",
            id,
        });
        return {
            type: "ok",
            result: {},
        };
    }
    async mkdir(path, recursive) {
        path = this.absoluteToRelativeWorkspacePath(path);
        const parts = path.split("/").filter(Boolean);
        const name = parts.pop();
        const parentPath = parts.join("/");
        if (parentPath == null || !name) {
            throw new Error("Invalid path");
        }
        const dirId = this.getIdFromPath(path);
        if (dirId) {
            const node = this.memoryFS.getNodeById(dirId);
            if (node?.type !== NodeType.File && recursive) {
                return {
                    type: "ok",
                    result: {},
                };
            }
            return {
                type: "error",
                error: "Directory already exists",
                errno: 17,
            };
        }
        let parentNode;
        if (!recursive) {
            const parentId = this.getIdFromPath(parentPath);
            parentNode = parentId ? this.memoryFS.getNodeById(parentId) : null;
        }
        else {
            parentNode = this.createParentDirs(parts, ROOT_ID);
        }
        if (!parentNode) {
            return {
                type: "error",
                error: "Parent directory not found",
                errno: 2,
            };
        }
        const id = newId();
        await this.applyFSOperation({
            type: "create",
            parentId: parentNode.id,
            newEntry: {
                id,
                type: bedrockFS.NodeType.Directory,
                name,
            },
        });
        return {
            type: "ok",
            result: {},
        };
    }
    queueWatchEvent(path, type) {
        path = this.relativeToAbsoluteWorkspacePath(path);
        if (this.activeWatchers.size === 0) {
            return;
        }
        const queue = this.watchQueue.get(type) ?? new Set();
        queue.add(path);
        this.watchQueue.set(type, queue);
    }
    emitWatchEvents() {
        for (const [type, paths] of this.watchQueue) {
            if (!paths.size) {
                continue;
            }
            // clear queue
            this.watchQueue.delete(type);
            const pathsArray = [...paths];
            // go through all watchers and than filter paths for each watcher and emit if any relevant paths changed
            this.activeWatchers.forEach((watcher) => {
                const wildcardMatcher = `${watcher.path}/`.replace(/\/+/, "/");
                const filteredPaths = pathsArray.filter((v) => {
                    return (v === watcher.path ||
                        (watcher.options.recursive && v.startsWith(wildcardMatcher)));
                });
                if (filteredPaths.length) {
                    watcher.onEvent({
                        type,
                        paths: filteredPaths,
                    });
                }
            });
        }
    }
    async watch(path, options, onEvent) {
        path = this.absoluteToRelativeWorkspacePath(path);
        const watchId = newId();
        this.activeWatchers.set(watchId, {
            path,
            options,
            onEvent,
        });
        return Promise.resolve({
            type: "success",
            dispose: () => {
                this.activeWatchers.delete(watchId);
            },
        });
    }
    openSeamlessBranchBarrier() {
        this.seamlessForkBarrier.open(undefined);
    }
}
function findQueryPositions(input, query, caseSensitive) {
    const flags = caseSensitive ? "g" : "gi";
    const regexPattern = new RegExp(query, flags); // 'g' for global and 'i' for case-insensitive
    const positions = [];
    let match;
    while ((match = regexPattern.exec(input)) !== null) {
        const startPosition = match.index;
        const endPosition = startPosition + match[0].length;
        positions.push({
            start: startPosition,
            end: endPosition,
            match: { text: input.slice(startPosition, endPosition) },
        });
    }
    return positions;
}
