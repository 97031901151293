import type { Id } from "@codesandbox/pitcher-common";
import {
  SimpleTerminalBackend,
  SimpleTerminalProcess,
} from "@codingame/monaco-vscode-terminal-service-override";
import { Emitter } from "monaco-editor";
import type { IDisposable } from "monaco-editor";
import type { IProcessEnvironment } from "vscode/vscode/vs/base/common/platform";
import type {
  IShellLaunchConfig,
  ITerminalLaunchError,
  ITerminalProcessOptions,
} from "vscode/vscode/vs/platform/terminal/common/terminal";

import { getLatestMonacoConfig } from "../monaco";
import { runWithLatestMonacoConfig } from "../tools/with-monaco-config";

export class PitcherTerminalBackend extends SimpleTerminalBackend {
  private latestId = 1; // start at 0, otherwise a check in VSCode doesn't fulfill

  getDefaultSystemShell = async () => `zsh`;
  createProcess = async (
    _shellLaunchConfig: IShellLaunchConfig,
    cwd: string,
    cols: number,
    rows: number,
    _unicodeVersion: "6" | "11",
    _env: IProcessEnvironment,
    _options: ITerminalProcessOptions,
    _shouldPersist: boolean,
  ) => {
    const onDataEmitter = new Emitter<string>();
    const pitcher = getLatestMonacoConfig().pitcher;
    const id = this.latestId++;

    if (!pitcher.permissions.shell?.create) {
      return new ReadonlyPitcherTerminalProcess(id, id, cwd, onDataEmitter);
    }
    const shell = await pitcher.clients.shell.create(pitcher.workspacePath, {
      cols,
      rows,
    });

    const process = new PitcherTerminalProcess(
      id,
      id,
      cwd,
      cols,
      rows,
      onDataEmitter,
      shell.shellId,
    );

    return process;
  };
}

class PitcherTerminalProcess
  extends SimpleTerminalProcess
  implements IDisposable
{
  private disposables: IDisposable[] = [];

  private onProcessExitEmitter = new Emitter<number | undefined>();
  public onProcessExit = this.onProcessExitEmitter.event;

  constructor(
    id: number,
    pid: number,
    cwd: string,
    private cols: number,
    private rows: number,
    private onDataEmitter: Emitter<string>,
    private readonly shellId: Id,
  ) {
    super(id, pid, cwd, onDataEmitter.event);

    this.disposables.push(onDataEmitter);
    this.disposables.push(
      runWithLatestMonacoConfig((config) =>
        config.pitcher.clients.shell.onShellOut(({ shellId, out }) => {
          if (shellId === this.shellId) {
            this.onDataEmitter.fire(out);
          }
        }),
      ),
    );

    this.disposables.push(
      runWithLatestMonacoConfig((config) =>
        config.pitcher.clients.shell.onShellExited(({ shellId, exitCode }) => {
          if (shellId === this.shellId) {
            this.onProcessExitEmitter.fire(exitCode);
          }
        }),
      ),
    );
  }

  clearBuffer(): void | Promise<void> {
    getLatestMonacoConfig().pitcher.clients.shell.send(this.shellId, "\x0c", {
      cols: this.cols,
      rows: this.rows,
    });
  }

  async start(): Promise<
    ITerminalLaunchError | { injectedArgs: string[] } | undefined
  > {
    return undefined;
  }

  shutdown(_immediate: boolean): void {
    getLatestMonacoConfig()
      .pitcher.clients.shell.delete(this.shellId)
      .then((res) => {
        this.onProcessExitEmitter.fire(res?.exitCode);
        this.dispose();
      });
  }

  input(data: string): void {
    getLatestMonacoConfig().pitcher.clients.shell.send(this.shellId, data, {
      cols: this.cols,
      rows: this.rows,
    });
  }

  resize(cols: number, rows: number): void {
    this.cols = cols;
    this.rows = rows;

    getLatestMonacoConfig().pitcher.clients.shell.resize(this.shellId, {
      cols,
      rows,
    });
  }

  dispose() {
    this.disposables.forEach((d) => d.dispose());
  }
}

class ReadonlyPitcherTerminalProcess extends SimpleTerminalProcess {
  constructor(
    id: number,
    pid: number,
    cwd: string,
    private onDataEmitter: Emitter<string>,
  ) {
    super(id, pid, cwd, onDataEmitter.event);
  }

  clearBuffer(): void | Promise<void> {}
  start(): Promise<
    ITerminalLaunchError | { injectedArgs: string[] } | undefined
  > {
    this.onDataEmitter.fire(
      "This terminal is readonly, fork this devbox/branch to create new terminals.",
    );

    return Promise.resolve(undefined);
  }
  shutdown(_immediate: boolean): void {}
  input(_data: string): void {}
  resize(_cols: number, _rows: number): void {}
}
