import { Emitter } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../common/AsyncValueStore";
export class TaskClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.taskListUpdateEmitter = new Emitter();
        this.onTaskListUpdate = this.taskListUpdateEmitter.event;
        this.taskUpdateEmitter = new Emitter();
        this.onTaskUpdate = this.taskUpdateEmitter.event;
        this.taskPortOpenedEmitter = new Emitter();
        this.onTaskPortOpened = this.taskPortOpenedEmitter.event;
        this.taskStartedEmitter = new Emitter();
        this.onTaskStarted = this.taskStartedEmitter.event;
        this.unassignedPortOpenedEmitter = new Emitter();
        this.onUnassignedPortOpened = this.unassignedPortOpenedEmitter.event;
        this.unassignedPortClosedEmitter = new Emitter();
        this.onUnassignedPortClosed = this.unassignedPortClosedEmitter.event;
        this.configParseErrorEmitter = new Emitter();
        this.onConfigParseError = this.configParseErrorEmitter.event;
        this.tasksValue = this.createTasksValue();
        this.readyPromise = this.tasksValue.getInitPromise();
        messageHandler.onNotification("task/listUpdate", (value) => {
            // Will emit changed event
            this.tasksValue.set(value);
        });
        messageHandler.onNotification("task/update", (task) => {
            const taskId = task.id;
            const oldTask = this.tasksValue.get().tasks[taskId];
            // Update the pitcher client state with the new task
            this.tasksValue.update((crtValue) => ({
                ...crtValue,
                tasks: {
                    ...crtValue.tasks,
                    [taskId]: task,
                },
            }));
            this.taskUpdateEmitter.fire(task);
            // If the shell transitioned to RUNNING, emit taskStarted event
            if (oldTask?.shell?.status !== "RUNNING" &&
                task.shell?.status === "RUNNING") {
                this.taskStartedEmitter.fire({ taskId });
            }
            if (oldTask) {
                // Call special listener when task ports are open
                task.ports.forEach((p) => {
                    const portIsNew = !oldTask.ports.some((tp) => tp.port === p.port);
                    if (portIsNew) {
                        this.taskPortOpenedEmitter.fire({ taskId, port: p });
                    }
                });
            }
        });
        // TODO: Figure out why pitcher doesn't send task/update on shell/exit here
        messageHandler.onNotification("shell/exit", ({ shellId, exitCode }) => {
            const tasks = this.tasksValue.get().tasks;
            const taskToUpdate = Object.values(tasks).find((t) => t.shell?.shellId === shellId);
            if (!taskToUpdate || taskToUpdate.shell === null) {
                return;
            }
            const updatedTask = {
                ...taskToUpdate,
                shell: {
                    ...taskToUpdate.shell,
                    status: exitCode > 0 ? "ERROR" : "FINISHED",
                    exitCode,
                },
            };
            this.tasksValue.update((crtValue) => ({
                ...crtValue,
                tasks: {
                    ...crtValue.tasks,
                    [updatedTask.id]: updatedTask,
                },
            }));
            this.taskUpdateEmitter.fire(updatedTask);
        });
        messageHandler.onNotification("task/unassignedPortOpened", (value) => {
            this.unassignedPortOpenedEmitter.fire(value);
        });
        messageHandler.onNotification("task/unassignedPortClosed", (value) => {
            this.unassignedPortClosedEmitter.fire(value);
        });
        messageHandler.onNotification("task/configParseError", ({ error }) => {
            this.configParseErrorEmitter.fire(error);
        });
    }
    createTasksValue() {
        const value = new AsyncValueStore({
            tasks: {},
            setupTasks: [],
            validationErrors: [],
        }, () => this.messageHandler
            .request({
            method: "task/list",
            params: {},
        })
            .then((value) => {
            return value;
        }), { fetchEagerly: true });
        value.onChange(({ value }) => {
            this.taskListUpdateEmitter.fire(value);
        });
        return value;
    }
    getTasks() {
        return this.tasksValue.get();
    }
    getTask(taskId) {
        const tasks = this.tasksValue.get().tasks;
        return tasks[taskId];
    }
    resync() {
        return this.tasksValue.refresh();
    }
    runTask(taskId) {
        const task = this.tasksValue.get().tasks[taskId];
        if (task && task.shell?.status === "KILLED") {
            // Optimistic update, clear old shell reference until new one comes from pitcher
            task.shell = null;
        }
        return this.messageHandler.request({
            method: "task/run",
            params: {
                taskId,
            },
        }, {
            queueForReconnect: true,
        });
    }
    runCommand(command, saveToConfig, name) {
        return this.messageHandler.request({
            method: "task/runCommand",
            params: {
                command,
                name,
                saveToConfig,
            },
        }, {
            seamlessForkStrategy: "queue",
        });
    }
    stopTask(taskId) {
        return this.messageHandler.request({
            method: "task/stop",
            params: {
                taskId,
            },
        }, {
            queueForReconnect: true,
        });
    }
    updateTask(taskId, taskFields) {
        return this.messageHandler.request({
            method: "task/update",
            params: {
                taskId,
                taskFields,
            },
        }, {
            seamlessForkStrategy: "queue",
        });
    }
    async createTask(taskFields, startTask = false) {
        const tasks = await this.messageHandler.request({
            method: "task/create",
            params: {
                taskFields,
                startTask,
            },
        }, {
            seamlessForkStrategy: "queue",
        });
        // Creating a task will return the entire list, so the reference inside the client is updated
        this.tasksValue.set(tasks);
        // However, we grab the created task back from the list,
        // to be dispatched by the environment as a separate event
        const tasksObject = this.tasksValue.get().tasks;
        const createdTask = Object.values(tasksObject).find((task) => task.command === taskFields.command);
        if (!createdTask) {
            throw new Error(`Cannot find the task running '${taskFields.command}'`);
        }
        return createdTask;
    }
    saveToConfig(taskId) {
        return this.messageHandler.request({
            method: "task/saveToConfig",
            params: {
                taskId,
            },
        });
    }
    generateConfigFile() {
        return new Promise((resolve, reject) => {
            // Listen to create operation for the `tasks.json` file and catch the Id
            const unsubscribe = this.messageHandler.onNotification("fs/operations", ({ operations }) => {
                const createOperation = operations
                    .map((ev) => ev.operation)
                    .find((op) => op.type === "create" && op.newEntry.name === "tasks.json");
                if (!createOperation || createOperation.type !== "create") {
                    return;
                }
                const fileId = createOperation.newEntry.id;
                resolve(fileId);
                unsubscribe();
            });
            // Timeout after 5 seconds for edge cases where the file is not created
            setTimeout(() => {
                reject(new Error("Config file was not generated"));
                unsubscribe();
            }, 5000);
            this.messageHandler
                .request({
                method: "task/generateConfig",
                params: {},
            })
                .catch((err) => {
                reject(err);
                unsubscribe();
            });
        });
    }
    createSetupTasks(tasks) {
        return this.messageHandler.request({
            method: "task/createSetupTasks",
            params: { tasks },
        });
    }
}
