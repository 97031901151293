import { Disposable, Emitter } from "@codesandbox/pitcher-common";
import { listenOnce } from "@codesandbox/pitcher-common/dist/event";
// TODO: use pitcher-common sleep for this, next version makes it
// disposable
import { sleep } from "./sleep";
export class CancellationToken extends Disposable {
    constructor() {
        super();
        this.onCancellationRequestedEmitter = this.addDisposable(new Emitter());
        this.onCancellationRequested = this.onCancellationRequestedEmitter.event;
        this.cancellationRequested = false;
        this.onWillDispose(() => {
            this.requestCancellation();
        });
    }
    isCancellationRequested() {
        return this.cancellationRequested;
    }
    requestCancellation() {
        this.cancellationRequested = true;
        this.onCancellationRequestedEmitter.fire();
    }
    throwErrorIfCancelled(cleanup) {
        if (this.cancellationRequested) {
            cleanup?.();
            throw new CancellationError();
        }
    }
}
export class CancellationError extends Error {
    constructor(sourceError) {
        super("Cancelled");
        this.sourceError = sourceError;
    }
}
const TIMEOUT_SYMBOL = Symbol("TIMEOUT_SYMBOL");
/**
 * Retries a promise until it succeeds. If the promise times out, or the promise returns an error,
 * we trigger a cancellation token which can be used to do a proper cleanup.
 */
export async function retryPromise(cb, tries, delayMs, timeoutMs) {
    let cancellationToken;
    let result;
    for (let i = 0; i < tries; i++) {
        cancellationToken = new CancellationToken();
        try {
            const sleepRef = sleep(timeoutMs);
            const raceResult = await Promise.race([
                cb(cancellationToken),
                // Since callbacks can return undefined, we need a recognizeable symbol to evaluate
                // if we hit the timeout
                sleepRef.then(() => TIMEOUT_SYMBOL),
                listenOnce(cancellationToken.onCancellationRequested),
            ]);
            sleepRef.dispose();
            if (cancellationToken.isCancellationRequested()) {
                throw new CancellationError();
            }
            // When hitting a timeout we ask for cancellation, so that any async logic in the callback
            // still runs, it can clean itself up
            if (raceResult === TIMEOUT_SYMBOL) {
                cancellationToken.requestCancellation();
                // We will still exhaust our retries even though the timeout hit, or we would throw
                // a CancellationError
                throw new Error("Timed out after " + timeoutMs + "ms");
            }
            // We succesfully connected, break out of the loop
            result = raceResult;
            break;
        }
        catch (e) {
            // If cancellation was requested from within the callback, we stop retrying
            if (e instanceof CancellationError) {
                throw e.sourceError || e;
            }
            const isLastRetry = i === tries - 1;
            if (isLastRetry) {
                throw e;
            }
            await sleep(delayMs);
        }
    }
    return result;
}
