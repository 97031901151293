import type { EditorRouter, QueryApi } from "features/types/router";
import { createContext, useContext, useEffect, useState } from "react";

import { router } from "../router";
import {
  getBranchUrlParams,
  getDevboxUrlParams,
  getSandboxUrlParams,
} from "../utils/router";

export const context = createContext<EditorRouter>({} as EditorRouter);

/**
 * This utility allows us to create a typed query API
 */
export function createQueryApi<T>(
  key: string,
  options: {
    isJson?: boolean;
    preventEncoding?: boolean;
  } = {
    isJson: false,
    preventEncoding: false,
  },
): QueryApi<T> {
  return {
    get() {
      if (!(key in router.queries)) {
        return undefined;
      }
      const value = router.queries[key] ?? "";

      return options.isJson ? JSON.parse(decodeURIComponent(value)) : value;
    },
    set(value: T) {
      if (value === undefined) {
        return false;
      }

      const stringValue = options.isJson
        ? JSON.stringify(value)
        : String(value);

      router.setQuery(
        key,
        options.preventEncoding ? stringValue : encodeURIComponent(stringValue),
      );

      return true;
    },
    delete() {
      router.setQuery(key, undefined);

      return true;
    },
  };
}

function getRouterType(pathname: string) {
  if (pathname.startsWith("/sandbox")) {
    return "sandbox";
  }
  if (pathname.startsWith("/devbox") || pathname.startsWith("/live")) {
    return "devbox";
  }

  return "branch";
}

function createEditorRouter(): EditorRouter {
  return {
    instance: router,
    get type() {
      return getRouterType(router.pathname);
    },
    queryParams: {
      file: createQueryApi("file", {
        preventEncoding: true,
      }),
      /**
       * Any shared layout features (panels of devtools, files)
       */
      layout: createQueryApi("layout"),

      /**
       * Different layout/editor modes, future proof, for now only `review` exists
       */
      mode: createQueryApi("mode"),

      /**
       * Simple boolean to override show/hide the sidebar from the URL
       */
      sidebar: createQueryApi("sidebar", { isJson: true }),

      /**
       * Workspace id the project is assigned to
       */
      workspaceId: createQueryApi("workspaceId"),

      /**
       * Should the welcome modal be shown
       */
      welcome: createQueryApi("welcome"),

      showConsole: createQueryApi("showConsole"),

      migrateFrom: createQueryApi("migrateFrom"),

      fork: createQueryApi("fork"),

      privacy: createQueryApi("privacy"),

      createRepo: createQueryApi("createRepo"),
    },
    branch: {
      get urlParams() {
        const { owner, repo, branch } = getBranchUrlParams(router.pathname);
        if (!owner || !repo) {
          throw new Error(
            "The Editor Router is being used on a url without OWNER or REPO",
          );
        }

        return {
          owner,
          repo,
          branch,
        };
      },
      queryParams: {
        /**
         * Used to create a new branch
         * - ?create=true -> will create a new draft branch with a random name
         *  - /my-branch?create=true -> will create `my-branch`
         */
        create: createQueryApi("create"),

        checkout: createQueryApi("checkout"),

        /**
         * If this is set, we know that we're importing a repo for the first time
         */
        import: createQueryApi("import"),
      },
      goToBranch({
        owner,
        repo,
        branch,
        workspaceId,
        addImportFlag,
        addPreventWorkspaceRedirectFlag,
        create,
        checkout,
      }) {
        const query = {
          ...router.queries,
          ...(workspaceId ? { workspaceId } : undefined),
          ...(addImportFlag ? { import: "true" } : undefined),
          ...(addPreventWorkspaceRedirectFlag
            ? { preventWorkspaceRedirect: "true" }
            : undefined),
          ...(create ? { create: "true" } : undefined),
          ...(checkout ? { checkout: "true" } : undefined),
        };

        if (workspaceId) {
          // Cleanup any previous reference of preventWorkspaceRedirect
          delete query.preventWorkspaceRedirect;
        } else {
          // Remove workspaceId from query in case the backend returns null
          delete query.workspaceId;
        }

        router.push(
          "github",
          {
            owner,
            repo,
            branch,
          },
          query,
        );

        return true;
      },
      updateCurrentBranch({ owner, repo, branch: newBranch }) {
        router.replace(
          "github",
          {
            owner,
            repo,
            branch: newBranch,
          },
          router.queries,
        );

        return true;
      },
    },
    sandbox: {
      get urlParams() {
        const { id } = getSandboxUrlParams(router.pathname);
        if (!id) {
          throw new Error(
            "The Editor Router is being used on a url without ID",
          );
        }

        return {
          id,
        };
      },
      goToSandbox(alias) {
        router.push("sandbox", {
          id: alias,
        });

        return true;
      },
    },
    devbox: {
      get urlParams() {
        const { id } = getDevboxUrlParams(router.pathname);
        if (!id) {
          throw new Error(
            "The Editor Router is being used on a url without ID",
          );
        }

        return {
          id,
        };
      },
      goToDevbox(alias) {
        router.push("devbox", {
          id: alias,
        });

        return true;
      },
    },
  };
}

/**
 * This is a specific API exposed to deal with routing of the Project Editor.
 * It is typed and also deals with some specific routing orchestration related
 * to renaming branches for example
 */

const editorRouter = createEditorRouter();

export const EditorRouterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, setCurrentPath] = useState({
    pathname: router.pathname,
    router: editorRouter,
  });

  useEffect(
    () =>
      router.listen(() =>
        setCurrentPath((current) => {
          if (current.pathname === router.pathname) return current;
          // We need to change the router reference when the path changes to reconcile the components
          return { pathname: router.pathname, router: { ...editorRouter } };
        }),
      ),
    [],
  );

  return <context.Provider value={state.router}>{children}</context.Provider>;
};

export const useEditorRouter = (): EditorRouter => {
  return useContext(context);
};
