import { useEnvironmentInterface } from "environment-interface";
import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";

import { cookieConsentConfig } from "../utils/cookieConsent/cookieConsentConfig";

export const useCookieConsent = () => {
  const { amplitude, visibility } = useEnvironmentInterface();

  const handleCookieConsent = (cookie: CookieConsent.CookieValue) => {
    const allowAnalytics = cookie?.categories?.includes("analytical") || false;
    if (allowAnalytics) {
      amplitude.init();
    }

    amplitude.setOptOut(!allowAnalytics);
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== "production" || visibility.isInIframe) {
      return;
    }

    CookieConsent.run({
      onFirstConsent: ({ cookie }) => {
        handleCookieConsent(cookie);
      },
      onChange: ({ cookie }) => {
        handleCookieConsent(cookie);
      },
      ...cookieConsentConfig,
    });

    const cookieConsent = CookieConsent.getCookie();
    handleCookieConsent(cookieConsent);
  }, [visibility.isInIframe]);
};
