import { newId, Disposable, Emitter } from "@codesandbox/pitcher-common";
class AiChatMessageStream extends Disposable {
    constructor(id, role, username) {
        super();
        this.id = id;
        this.role = role;
        this.username = username;
        this.progressEmitter = new Emitter();
        this.onProgress = this.progressEmitter.event;
        this.doneEmitter = new Emitter();
        this.onDone = this.doneEmitter.event;
    }
    handleProgress(content) {
        this.progressEmitter.fire(content);
    }
    handleDone() {
        this.doneEmitter.fire();
    }
}
export class AiChat extends Disposable {
    constructor(chatId, title, isUpToDate, messageHandler, aiClient) {
        super();
        this.messageHandler = messageHandler;
        this.aiClient = aiClient;
        this.historyPromise = null;
        this.entries = [];
        this.isStreamingReply = false;
        this.chatMessageEmitter = new Emitter();
        this.onChatMessage = this.chatMessageEmitter.event;
        this.chatMessageStartedEmitter = new Emitter();
        this.onChatMessageStarted = this.chatMessageStartedEmitter.event;
        this.chatTitleChangeEmitter = new Emitter();
        this.onChatTitleChange = this.chatTitleChangeEmitter.event;
        this.chatId = chatId;
        this.title = title;
        this.isUpToDate = isUpToDate;
        const disposeNotificationListener = this.messageHandler.onNotification("ai/chatMessage", (evt) => {
            if (evt.chatId === this.chatId) {
                const stream = new AiChatMessageStream(evt.messageId, evt.role, evt.username);
                this.chatMessageStartedEmitter.fire(stream);
                if (evt.isFinished || !evt.messageId) {
                    this.addChatMessage(evt, true);
                    this.chatMessageEmitter.fire(evt);
                    stream.handleProgress(evt.message);
                    stream.handleDone();
                }
                else {
                    this.isStreamingReply = true;
                    const disposable = this.aiClient.subscribeToMessage(evt.messageId, (newContent, isFinished) => {
                        stream.handleProgress(newContent);
                        if (isFinished) {
                            const message = { ...evt, message: newContent };
                            this.addChatMessage(message, true);
                            this.isStreamingReply = false;
                            this.chatMessageEmitter.fire(message);
                            disposable.dispose();
                            stream.handleDone();
                        }
                    });
                }
            }
        });
        this.onWillDispose(() => disposeNotificationListener());
    }
    addChatMessage(message, checkHistory) {
        if (checkHistory && this.entries.length) {
            const lastMsgIdx = this.entries[this.entries.length - 1]?.idx ?? 0;
            if (lastMsgIdx !== message.idx - 1) {
                // eslint-disable-next-line
                console.warn("Chat message got lost", this, message);
                this.fetchHistory();
            }
        }
        this.entries.push({
            idx: message.idx,
            role: message.role,
            username: message.username,
            message: message.message,
            context: message.context,
        });
    }
    fetchHistory() {
        if (!this.historyPromise) {
            this.historyPromise = this.messageHandler
                .request({
                method: "ai/chatHistory",
                params: {
                    chatId: this.chatId,
                },
            })
                .then((res) => {
                this.isUpToDate = true;
                this.entries = res.entries;
                return this.entries;
            })
                .catch((err) => {
                if (!err.message?.includes("not found")) {
                    // eslint-disable-next-line
                    console.error(err);
                }
                return [];
            })
                .finally(() => {
                this.historyPromise = null;
            });
        }
        return this.historyPromise;
    }
    getHistory() {
        if (!this.isUpToDate) {
            return this.fetchHistory();
        }
        else {
            return Promise.resolve(this.entries);
        }
    }
    getHistorySync() {
        return this.entries;
    }
    async sendMessage(params) {
        if (this.isStreamingReply) {
            throw new Error("Cannot sent another request while we are waiting on a response");
        }
        const messageId = newId();
        const result = await this.messageHandler.request({
            method: "ai/chatMessage",
            params: {
                ...params,
                messageId,
                chatId: this.chatId,
            },
        });
        if (result.title !== this.title) {
            this.title = result.title;
            this.chatTitleChangeEmitter.fire(this.title);
        }
        const chatMessage = {
            ...params,
            chatId: this.chatId,
            role: "user",
            idx: this.entries.length,
        };
        this.addChatMessage(chatMessage, true);
    }
    async refresh() {
        await this.fetchHistory();
    }
}
