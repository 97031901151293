import type { clients, protocol, Id } from "@codesandbox/pitcher-client";
import { Debouncer } from "@codesandbox/pitcher-client";
import type { PitcherEvents } from "environment-interface/pitcher";
import type { FSApi } from "environment-interface/pitcher/fs";

import logger from "../../../features/utils/logger";

export interface SearchResultWithPath extends protocol.fs.SearchResult {
  path: string;
}

export const createFsApi = (
  fs: clients.IFSClient,
  pitcherEvents: PitcherEvents,
): FSApi => {
  const syncDebouncer = new Debouncer(100, 1000, () => {
    pitcherEvents.emit({
      type: "PITCHER:FS:DEBOUNCED_SYNC",
    });
  });

  fs.onFSError(logger.error);

  fs.onFSSync(() => {
    syncDebouncer.debounce();
  });

  fs.onPendingOperationsChange((pendingOperations) => {
    pitcherEvents.emit({
      type: "PITCHER:FS:PENDING_OPERATIONS_UPDATE",
      pendingOperationsIds: pendingOperations.map((operation) => {
        switch (operation.type) {
          case "create":
            return operation.newEntry.id;
          case "move":
            return operation.id;
          case "delete":
            return operation.id;
        }
      }),
    });
  });

  return {
    client: fs,
    getRelativePath(absolutePath: string) {
      return fs.absoluteToRelativeWorkspacePath(
        fs.asAbsoluteWorkspacePath(absolutePath),
      );
    },
    getAbsolutePath(relativePath: string) {
      return fs.relativeToAbsoluteWorkspacePath(
        fs.asRelativeWorkspacePath(relativePath),
      );
    },
    getPathById(fileId) {
      const relativePath = fs.getPathFromId(fileId);

      if (!relativePath) {
        throw new Error(
          `Failed to retrieve a path for the file (id: "${fileId}"): file not found.`,
        );
      }

      return relativePath;
    },
    pathSearch(params) {
      fs.pathSearch(params)
        .then((results) => {
          const { matches } = results;

          if (matches.length > 0) {
            pitcherEvents.emit({
              type: "PITCHER:FS:PATH_SEARCH_SUCCESS",
              matches: matches,
              term: params.text,
            });

            return;
          }

          pitcherEvents.emit({
            type: "PITCHER:FS:PATH_SEARCH_SUCCESS_NO_MATCHES",
          });
        })
        .catch((error: Error) => {
          pitcherEvents.emit({
            type: "PITCHER:FS:PATH_SEARCH_ERROR",
            error: error.message,
          });
        });
    },
    search(params) {
      fs.search(params)
        .then((results) => {
          if (results.length > 0) {
            pitcherEvents.emit({
              type: "PITCHER:FS:SEARCH_SUCCESS",
              results,
              params,
            });

            return;
          }

          pitcherEvents.emit({
            type: "PITCHER:FS:SEARCH_SUCCESS_NO_MATCHES",
            params,
          });
        })
        .catch((error: Error) => {
          pitcherEvents.emit({
            type: "PITCHER:FS:SEARCH_ERROR",
            error: error.message,
            params,
          });
        });
    },
    uploadFiles({ files, parentId }) {
      Promise.allSettled(
        files.map(async (f) => {
          const bufferedContent = await f.arrayBuffer();
          return fs.uploadFile(
            f.name,
            new Uint8Array(bufferedContent),
            parentId,
          );
        }),
      ).then((uploads) => {
        const result = uploads.reduce<{
          resolved: Array<{ id: Id; filepath: string }>;
          rejected: string[];
        }>(
          (aggr, upload, index) => {
            if (upload.status === "fulfilled") {
              return {
                ...aggr,
                resolved: aggr.resolved.concat(upload.value),
              };
            }
            return {
              ...aggr,
              rejected: aggr.rejected.concat(files[index].name),
            };
          },
          { resolved: [], rejected: [] },
        );

        pitcherEvents.emit({
          type: "PITCHER:FS:UPLOAD_FILES_FINISHED",
          resolvedFiles: result.resolved,
          rejectedNames: result.rejected,
        });
      });
    },
  };
};
