import { join as joinPaths } from "path";
import { newId, Emitter, bedrockFS, SerialQueue, } from "@codesandbox/pitcher-common";
import { PitcherErrorCode, fs as fsProtocol, } from "@codesandbox/pitcher-protocol";
import { MemoryFS } from "../../../common/MemoryFS";
export * from "../../../common/MemoryFS";
// Paths can currently come from different sources. Users, Docker and Pitcher-Host all operate
// under different workspace paths. To resolve a relative path we need to test for all of these as they all
// can be exposed in different contexts. Later we'll operate with a single workspace path
export const USER_WORKSPACE_PATH_REGEXP = new RegExp(/^\/project\/home\/[a-zA-Z0-9 -_]+\/workspace/gm);
export const DOCKER_WORKSPACE_PATH_REGEXP = new RegExp(/^\/workspace/gm);
export const PITCHER_HOST_WORKSPACE_PATH_REGEXP = new RegExp(/^\/project\/.*?\//gm);
export const DEVCONTAINER_WORKSPACE_PATH_REGEXP = new RegExp(/^\/workspaces\/.*?\//gm);
const workspacePaths = [
    DEVCONTAINER_WORKSPACE_PATH_REGEXP,
    DOCKER_WORKSPACE_PATH_REGEXP,
    USER_WORKSPACE_PATH_REGEXP,
    PITCHER_HOST_WORKSPACE_PATH_REGEXP,
];
function removeWorkspacePath(path) {
    for (const relativeRegex of workspacePaths) {
        if (relativeRegex.test(path)) {
            return path.replace(relativeRegex, "");
        }
    }
    return path;
}
export class FSClient {
    constructor(workspacePath, userWorkspacePath, messageHandler) {
        this.workspacePath = workspacePath;
        this.userWorkspacePath = userWorkspacePath;
        this.messageHandler = messageHandler;
        this.memoryFS = new MemoryFS();
        this.operationQueue = new SerialQueue("fs-client");
        this.onFSErrorEmitter = new Emitter();
        this.onFSError = this.onFSErrorEmitter.event;
        this.onFSSyncEmitter = new Emitter();
        this.onFSSync = this.onFSSyncEmitter.event;
        this.onPendingOperationsChangeEmitter = new Emitter();
        this.onPendingOperationsChange = this.onPendingOperationsChangeEmitter.event;
        // Expose FS methods in MemoryFS
        this.getPathFromId = this.memoryFS.getPathFromId.bind(this.memoryFS);
        this.getIdFromPath = this.memoryFS.getIdFromPath.bind(this.memoryFS);
        messageHandler.onNotification("fs/operations", ({ operations }) => {
            for (const evt of operations) {
                this.memoryFS.receiveNotification(evt);
                const opCount = this.memoryFS.syncFSTree([]);
                this.onFSSyncEmitter.fire({
                    opCount,
                });
            }
        });
        this.readyPromise = this.readFs();
    }
    /**
     * When you have a path you know is relative, but you want to to ensure the correct format with
     * prefixed "/" and type safety
     */
    asRelativeWorkspacePath(path) {
        return joinPaths("/", path);
    }
    /**
     * When you know you have an absolute path, but want to ensure it to be this users workspace and have type safety
     */
    asAbsoluteWorkspacePath(path) {
        return joinPaths(this.workspacePath, removeWorkspacePath(path));
    }
    /**
     * When you have a type safe absolute path and want to make it relative
     */
    absoluteToRelativeWorkspacePath(path) {
        return joinPaths("/", removeWorkspacePath(path));
    }
    /**
     * When you have a tpe safe relative path and want to make it absolute to this users workspace path
     */
    relativeToAbsoluteWorkspacePath(path) {
        return joinPaths(this.workspacePath, path);
    }
    // When you are not sure about the path being relative or absolute, but you want a relative representation.
    // NOTE! This does a best guess as there is a risk the path passed is actually relative to the workspace, but
    // contains the path to the workspace itself, meaning it will be removed
    resolveRelativeWorkspacePath(path) {
        return joinPaths("/", removeWorkspacePath(path));
    }
    // When you are not sure about the path being relative or absolute, but you want an absolute representation for this
    // users workspace path.
    // NOTE! This does a best guess as there is a risk the path passed is actually relative to the workspace, but
    // contains the path to the workspace itself, meaning it will be removed
    resolveAbsoluteWorkspacePath(path) {
        return this.asAbsoluteWorkspacePath(path);
    }
    getPendingOperations() {
        return Array.from(this.memoryFS["pendingOperations"].values());
    }
    readFs() {
        return this.messageHandler
            .request({
            method: "fs/read",
            params: null,
        })
            .then((result) => {
            return this.memoryFS.populateTreeFromJSON(result);
        });
    }
    async resync() {
        // eslint-disable-next-line
        return this.readFs().catch(console.error);
    }
    applyFSOperation(operation) {
        const operationId = this.memoryFS.applyPendingOperation(operation);
        if (operationId === false) {
            return Promise.resolve();
        }
        this.onPendingOperationsChangeEmitter.fire(this.getPendingOperations());
        // operations should be applied one by one to ensure we don't get issues like `parent dir does not exist`
        // if an operation depends on the previous one
        return this.operationQueue.add(() => {
            return this.messageHandler
                .request({
                method: "fs/operation",
                params: {
                    operation,
                },
            }, {
                seamlessForkStrategy: "queue",
            })
                .then((result) => {
                if (result.code === fsProtocol.FSOperationResponseCode.Success) {
                    this.memoryFS.receiveNotification({
                        clock: result.clock,
                        operation,
                    });
                }
            })
                .catch((error) => {
                this.onFSErrorEmitter.fire({
                    message: error.message,
                });
            })
                .finally(() => {
                const opCount = this.memoryFS.syncFSTree([operationId]);
                // Not sure if this should fire an event?
                this.onFSSyncEmitter.fire({
                    opCount,
                });
                this.onPendingOperationsChangeEmitter.fire(this.getPendingOperations());
            });
        });
    }
    createParentDirs(parts, parentId) {
        let previousNode = this.memoryFS.tree.getNodeById(parentId);
        if (!previousNode || !previousNode.isDirNode()) {
            throw new Error(previousNode
                ? `${previousNode.path} is not a directory`
                : "Parent directory not found");
        }
        for (const part of parts) {
            const foundDirectory = previousNode.children.find((c) => c.name === part);
            if (!foundDirectory) {
                const dirId = this.createNewDirectory(part, previousNode.id);
                previousNode = this.memoryFS.tree.getNodeById(dirId);
            }
            else {
                if (!foundDirectory.isDirNode()) {
                    throw new Error(`${foundDirectory.path} already exists but is not a directory`);
                }
                previousNode = foundDirectory;
            }
        }
        return previousNode;
    }
    /**
     * Create a file with the provided name within the directory of the given
     * parent id.
     * */
    async createFile(name, parentId) {
        const parts = name.split("/").filter(Boolean);
        const filename = parts.pop();
        if (!filename) {
            throw new Error("File name is undefined");
        }
        const parentDirNode = this.createParentDirs(parts, parentId);
        const id = newId();
        await this.applyFSOperation({
            type: "create",
            parentId: parentDirNode.id,
            newEntry: {
                id,
                type: bedrockFS.NodeType.File,
                name: filename,
            },
            // eslint-disable-next-line
        }).catch(console.error);
        return {
            id,
            filepath: this.getPathFromId(id),
        };
    }
    isFile(id) {
        const node = this.memoryFS.getNodeById(id);
        return node ? node.isFile() : false;
    }
    createNewDirectory(name, parentId) {
        const id = newId();
        this.applyFSOperation({
            type: "create",
            parentId,
            newEntry: {
                id,
                type: bedrockFS.NodeType.Directory,
                name,
            },
            // eslint-disable-next-line
        }).catch(console.error);
        return id;
    }
    /**
     * Create a directory with the provided name within the directory of the
     * given parent id.
     * */
    createDirectory(name, parentId) {
        const parts = name.split("/").filter(Boolean);
        if (!parts.length) {
            throw new Error("Directory name is undefined");
        }
        const dirNode = this.createParentDirs(parts, parentId);
        return dirNode.id;
    }
    /**
     * delete a node from the FS
     * */
    deleteNode(id) {
        this.applyFSOperation({
            type: "delete",
            id,
            // eslint-disable-next-line
        }).catch(console.error);
    }
    /**
     * rename a node from the FS
     * */
    move(id, opts) {
        const { newName, newParentId } = opts;
        this.applyFSOperation({
            type: "move",
            id,
            parentId: newParentId,
            name: newName,
            // eslint-disable-next-line
        }).catch(console.error);
    }
    /**
     * Searches for a string literal within the files of a workspace.
     * Doesn't search the contents of gitignored files.
     */
    async search(params) {
        const result = await this.messageHandler.request({
            method: "fs/search",
            params: params,
        });
        // Pitcher returns the position in the text buffer, which is utf-8 encoded
        // JavaScript strings are "usually" utf-16 encoded so we need to convert the start and end index to match that
        // 1. Encode string to a utf-8 buffer
        // 2. Take the part of that buffer that matches the start and end pitcher sends
        // 3. Convert that part back into a string and take the length, this is now the actual index according to JavaScript string's encoding
        // 4. Take the remapped start index and length of the match to get the actual end position
        const encoder = new TextEncoder();
        const decoder = new TextDecoder();
        return result.map((result) => {
            const originalMatches = result.submatches;
            const newSubmatches = [];
            const lineText = result.lines.text;
            if (originalMatches.length > 0) {
                const buffer = encoder.encode(lineText);
                for (const submatch of originalMatches) {
                    const substring = decoder.decode(buffer.slice(0, submatch.start));
                    const actualStart = substring.length;
                    const actualEnd = actualStart + submatch.match.text.length;
                    newSubmatches.push({
                        ...submatch,
                        start: actualStart,
                        end: actualEnd,
                    });
                }
            }
            else {
                newSubmatches.push({
                    start: 0,
                    end: 1,
                    match: { text: lineText[0] ?? "" },
                });
            }
            return { ...result, submatches: newSubmatches };
        });
    }
    /**
     * Searches for a string literal within the files of a workspace.
     * Doesn't search the contents of gitignored files.
     */
    async streamingSearch(params, onMatches, abort) {
        const searchId = newId();
        let promiseResolver;
        let promiseRejector;
        const completionPromise = new Promise((resolve, reject) => {
            promiseResolver = resolve;
            promiseRejector = reject;
        });
        const disposables = [];
        const dispose = () => {
            for (const disposable of disposables) {
                disposable();
            }
        };
        const abortDisposable = abort.onDidDispose(() => {
            // Dispose and resolve search
            dispose();
            promiseResolver({ hitLimit: false });
            // Cancel search
            this.messageHandler
                .request({
                method: "fs/cancelStreamingSearch",
                params: {
                    searchId,
                },
            })
                .catch((err) => {
                promiseRejector(err);
            });
        });
        disposables.push(() => abortDisposable.dispose());
        const disposeSearchMatchesNotif = this.messageHandler.onNotification("fs/searchMatches", ({ searchId: notificationSearchId, matches }) => {
            if (searchId !== notificationSearchId) {
                return;
            }
            // Pitcher returns the position in the text buffer, which is utf-8 encoded
            // JavaScript strings are "usually" utf-16 encoded so we need to convert the start and end index to match that
            // 1. Encode string to a utf-8 buffer
            // 2. Take the part of that buffer that matches the start and end pitcher sends
            // 3. Convert that part back into a string and take the length, this is now the actual index according to JavaScript string's encoding
            // 4. Take the remapped start index and length of the match to get the actual end position
            const encoder = new TextEncoder();
            const decoder = new TextDecoder();
            const remappedMatches = matches.map((result) => {
                const originalMatches = result.submatches;
                const newSubmatches = [];
                const lineText = result.lines.text;
                if (originalMatches.length > 0) {
                    const buffer = encoder.encode(lineText);
                    for (const submatch of originalMatches) {
                        const substring = decoder.decode(buffer.slice(0, submatch.start));
                        const actualStart = substring.length;
                        const actualEnd = actualStart + submatch.match.text.length;
                        newSubmatches.push({
                            ...submatch,
                            start: actualStart,
                            end: actualEnd,
                        });
                    }
                }
                else {
                    newSubmatches.push({
                        start: 0,
                        end: 1,
                        match: { text: lineText[0] ?? "" },
                    });
                }
                return { ...result, submatches: newSubmatches };
            });
            onMatches(remappedMatches);
        });
        disposables.push(disposeSearchMatchesNotif);
        const disposeSearchCompletionNotif = this.messageHandler.onNotification("fs/searchFinished", ({ searchId: notificationSearchId, hitLimit }) => {
            if (searchId !== notificationSearchId) {
                return;
            }
            promiseResolver({ hitLimit });
        });
        disposables.push(disposeSearchCompletionNotif);
        await this.messageHandler
            .request({
            method: "fs/streamingSearch",
            params: {
                ...params,
                searchId,
            },
        })
            .catch((err) => {
            dispose();
            promiseRejector(err);
        });
        return completionPromise.finally(() => dispose());
    }
    /**
     * Searches for a filepath within the project
     * Query can be a literal string or a unix-like pattern e.g. *.test.ts
     */
    pathSearch(params) {
        return this.messageHandler.request({
            method: "fs/pathSearch",
            params,
        });
    }
    async uploadFile(name, content, parentId) {
        const parts = name.split("/").filter(Boolean);
        const filename = parts.pop();
        if (!filename) {
            throw new Error("File name is undefined");
        }
        const parentDirNode = this.createParentDirs(parts, parentId);
        const result = await this.messageHandler.request({
            method: "fs/upload",
            params: {
                parentId: parentDirNode.id,
                filename,
                content,
            },
        }, {
            seamlessForkStrategy: "queue",
        });
        return {
            id: result.fileId,
            filepath: this.getPathFromId(result.fileId),
        };
    }
    async download(path) {
        const result = await this.messageHandler.request({
            method: "fs/download",
            params: {
                path: path || this.workspacePath,
            },
        }, {});
        return {
            downloadUrl: result.downloadUrl,
        };
    }
    isInWorkspacePath(path) {
        return (path.startsWith(this.workspacePath + "/") ||
            path.startsWith(this.userWorkspacePath + "/"));
    }
    async readFile(path) {
        return this.handleRawFsResponse("fs/readFile", { path });
    }
    async readdir(path) {
        return this.handleRawFsResponse("fs/readdir", { path });
    }
    async writeFile(path, content, create = false, overwrite = false) {
        return this.handleRawFsResponse("fs/writeFile", {
            path,
            content,
            create,
            overwrite,
        }, !this.isInWorkspacePath(path));
    }
    async stat(path) {
        return this.handleRawFsResponse("fs/stat", { path });
    }
    async copy(from, to, recursive = false, overwrite = false) {
        return this.handleRawFsResponse("fs/copy", {
            from,
            to,
            recursive,
            overwrite,
        }, !this.isInWorkspacePath(from) && !this.isInWorkspacePath(to));
    }
    async rename(from, to, overwrite = false) {
        return this.handleRawFsResponse("fs/rename", { from, to, overwrite }, !this.isInWorkspacePath(from) && !this.isInWorkspacePath(to));
    }
    async remove(path, recursive = false) {
        return this.handleRawFsResponse("fs/remove", { path, recursive }, !this.isInWorkspacePath(path));
    }
    async mkdir(path, recursive = false) {
        return this.handleRawFsResponse("fs/mkdir", { path, recursive }, !this.isInWorkspacePath(path));
    }
    async watch(path, options, onEvent) {
        const response = await this.handleRawFsResponse("fs/watch", {
            path,
            recursive: options.recursive,
            // @ts-expect-error angry about using readonly here
            excludes: options.excludes,
        });
        if (response.type === "error") {
            return response;
        }
        const watchId = response.result.watchId;
        this.messageHandler.onNotification("fs/watchEvent", (params) => {
            if (params.watchId === watchId) {
                params.events.forEach(onEvent);
            }
        });
        return {
            type: "success",
            dispose: () => {
                this.handleRawFsResponse("fs/unwatch", { watchId });
            },
        };
    }
    async handleRawFsResponse(method, params, skipSeamlessFork = false) {
        try {
            const isModifyingRawOperation = method === "fs/copy" ||
                method === "fs/mkdir" ||
                method === "fs/writeFile" ||
                method === "fs/remove" ||
                method === "fs/rename";
            // The params are right, the result is right too
            // eslint-disable-next-line
            const result = await this.messageHandler.request({
                method,
                params,
            }, isModifyingRawOperation && !skipSeamlessFork
                ? {
                    seamlessForkStrategy: "queue",
                    queueForReconnect: true,
                }
                : {
                    queueForReconnect: true,
                });
            return { type: "ok", result };
        }
        catch (e) {
            // There's some weirdness with our error typing
            // eslint-disable-next-line
            const err = e;
            if ("code" in err) {
                if (err.code === PitcherErrorCode.RAWFS_ERROR) {
                    return {
                        type: "error",
                        error: err.message,
                        errno: err.data.errno,
                    };
                }
                return { type: "error", error: err.message, errno: null };
            }
            if (err instanceof Error) {
                return { type: "error", error: err.message, errno: null };
            }
            return { type: "error", error: "unknown error", errno: null };
        }
    }
}
