import type { Id, protocol, clients } from "@codesandbox/pitcher-client";
import type { PitcherEvents } from "environment-interface/pitcher";
import type { ShellApi } from "environment-interface/pitcher/shell";

export const createShellApi = (
  shellClient: clients.IShellClient,
  workspacePath: string,
  pitcherEvents: PitcherEvents,
): ShellApi => {
  shellClient.onShellCreated((shell) =>
    pitcherEvents.emit({
      type: "PITCHER:SHELL:UPSTREAM_SHELL_CREATED",
      shell,
    }),
  );

  shellClient.onShellTerminated(({ shellId, author }) =>
    pitcherEvents.emit({
      type: "PITCHER:SHELL:UPSTREAM_SHELL_TERMINATED",
      shellId,
      author,
    }),
  );

  shellClient.onShellExited(({ shellId, exitCode }) =>
    pitcherEvents.emit({
      type: "PITCHER:SHELL:UPSTREAM_SHELL_EXITED",
      shellId,
      exitCode,
    }),
  );

  return {
    getShells() {
      return shellClient.getShells();
    },

    openShell(shellId: Id, size: protocol.shell.ShellSize) {
      shellClient
        .open(shellId, size)
        .then((shell) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:OPEN_SUCCESS",
            shell,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:OPEN_ERROR",
            error: error.message,
          });
        });
    },

    closeShell(shellId: Id) {
      shellClient
        .close(shellId)
        .then(() => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:CLOSE_SUCCESS",
            shellId,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:CLOSE_ERROR",
            error: error.message,
          });
        });
    },

    resizeShell(shellId: Id, size: protocol.shell.ShellSize) {
      shellClient
        .resize(shellId, size)
        .then(() => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:RESIZE_SUCCESS",
            shellId,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:RESIZE_ERROR",
            error: error.message,
          });
        });
    },

    writeToShell(shellId: Id, input: string, size: protocol.shell.ShellSize) {
      shellClient
        .send(shellId, input, size)
        .then(() => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:WRITE_SUCCESS",
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:WRITE_ERROR",
            error: error.message,
          });
        });
    },

    // TODO: Refactor this into event... now we create a bunch of emitters we don't even dispose
    listenShell(shellId: Id, callback: (out: string) => void) {
      shellClient.onShellOut((event) => {
        if (event.shellId === shellId) {
          callback(event.out);
        }
      });
    },

    createTerminalShell(terminalRef: string) {
      shellClient
        .create(workspacePath, {
          cols: 125,
          rows: 20,
        })
        .then((shell) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:CREATE_TERMINAL_SUCCESS",
            terminalRef,
            shell: shell as protocol.shell.OpenTerminalShellDTO,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:CREATE_TERMINAL_ERROR",
            terminalRef,
            error: error.message,
          });
        });
    },

    deleteShell(shellId: Id) {
      shellClient
        .delete(shellId)
        .then(() => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:DELETE_SUCCESS",
            shellId,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:DELETE_ERROR",
            error: error.message,
          });
        });
    },

    restartShell(shellId: Id) {
      shellClient
        .restart(shellId)
        .then(() => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:RESTART_SUCCESS",
            shellId,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SHELL:RESTART_ERROR",
            error: error.message,
          });
        });
    },
  };
};
