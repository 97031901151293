import type {
  clients,
  Id,
  RelativeWorkspacePath,
} from "@codesandbox/pitcher-client";
import type { PitcherEvents } from "environment-interface/pitcher";
import type {
  FileApi,
  IDocumentSelections,
} from "environment-interface/pitcher/file";

export const createFileApi = (
  fileClient: clients.IFileClient,
  pitcherEvents: PitcherEvents,
): FileApi => {
  fileClient.onFileMove(({ id, newPath }) => {
    pitcherEvents.emit({
      type: "PITCHER:FILE:MOVE",
      id,
      newPath,
    });
  });

  fileClient.onFileDelete(({ id }) => {
    pitcherEvents.emit({
      type: "PITCHER:FILE:DELETE",
      id,
    });
  });

  fileClient.onFileSave(({ id, savedHash }) => {
    const file = fileClient.getOpenedFile(id);

    if (file) {
      pitcherEvents.emit({
        type: "PITCHER:FILE:SAVE_SUCCESS",
        id,
        contentHash: file.contentHash,
        savedHash,
        filepath: file.path,
      });
    }
  });

  fileClient.onFileOpen((file) => {
    if (file.document) {
      // This only gets called if another client did some operation
      // Is disposed when closing the document
      file.document.onIncomingOperation(({ operation, revision }) => {
        pitcherEvents.emit({
          type: "PITCHER:FILE:DOCUMENT_OPERATION",
          id: file.id,
          operation,
          revision,
        });
      });

      file.document.onSelection(({ clientSelections, reason }) => {
        pitcherEvents.emit({
          type: "PITCHER:FILE:DOCUMENT_SELECTION",
          id: file.id,
          clientSelections: getSelectionsFromDocument(clientSelections),
          reason,
        });
      });
    }

    file.onDidClientJoin(({ clientId }) => {
      pitcherEvents.emit({
        type: "PITCHER:FILE:JOIN",
        id: file.id,
        clientId,
      });
    });

    file.onDidClientLeave(({ clientId }) => {
      pitcherEvents.emit({
        type: "PITCHER:FILE:LEAVE",
        id: file.id,
        clientId,
      });
    });

    // Forward this event to ensure dirty state can be managed properly
    file.onDidContentChange(({ contentHash }) => {
      pitcherEvents.emit({
        type: "PITCHER:FILE:CONTENT_CHANGE",
        id: file.id,
        contentHash,
        savedHash: file.savedHash,
        filepath: file.path,
      });
    });

    pitcherEvents.emit({
      type: "PITCHER:FILE:OPEN_SUCCESS",
      id: file.id,
      contentHash: file.contentHash,
      savedHash: file.savedHash,
      content: file.content,
      filepath: file.path,
    });
  });

  const getSelectionsFromDocument = (
    clientSelections: clients.ClientSelections,
  ): IDocumentSelections => {
    const selections: IDocumentSelections = {};
    clientSelections.forEach((value, clientId) => {
      selections[clientId] = value;
    });
    return selections;
  };

  return {
    getFileIdFromRelativePath: (filepath: RelativeWorkspacePath) => {
      return fileClient.getFileIdFromPath(filepath);
    },
    getRelativePathFromFileId: (fileId: Id) => {
      return fileClient.getPathFromFileId(fileId);
    },
  };
};
